<template>
	<div class="pages-body error-page flex flex-column">
		<div class="topbar p-3 flex justify-content-between flex-row align-items-center bg-red">
			<router-link class="topbar-left ml-3 flex" to="/">
				<div class="logo">
					<h2 class="text-light m-0 fg-white pl-0">E<span class="fg-yellow">N</span>AT.AT</h2>
				</div>
			</router-link>
			<router-link class="topbar-right mr-3 flex" to="/">
				<Button type="button" label="DASHBOARD" class="p-button-text p-button-plain topbar-button"></button>
			</router-link>
		</div>

		<div class="align-self-center mt-auto mb-auto">
			<div class="pages-panel card flex flex-column">
				<div class="pages-header px-3 py-1">
					<h2>FEHLER</h2>
				</div>
				<div class="card mt-3 px-6">
					<img src="layout/icons/cute-tech/001-cd.svg" width="250" height="250" alt=""/>
				</div>
				<div class="pages-detail pb-6">Die angefragte Ressource ist nicht verfügbar.</div>
				<router-link to="/">
					<Button type="button" label="ZUM DASHBOARD" class="p-button-text"></Button>
				</router-link>
			</div>
		</div>
	</div>

</template>

<script>
</script>

<style scoped>

</style>
